.fixphone {
    position: relative;
    width: 100%;
    z-index: 2;
    background: url("http://www.w3.org/2000/svg");
    /*  width: '100%';
    display: 'inline-flex';
    margin: '2px'; 
    height: 100%; */
    color: #e62375;
    margin-left: 12px;
    margin-top: 5px;
    font-size: 20px;
}

.fixphone .PhoneInputCountryIconImg {
    width: 100% !important;
}