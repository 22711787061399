#lastPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#lastPage > h2 {
  color: #e62375;
  margin-bottom: 15%;

}

#lastPage > p {
  color: white;
  margin-bottom: 15%;
  text-align: center;

}
#lastPage > .confirmButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 15%;
}
#lastPage > div >button {
  cursor: pointer;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  font-size: 16px;
  min-height: 33px;
  padding: 7px 12px;
  width: 60%;
  background-color: #e62375;
  color: white;
  z-index: 10;
  margin: 10px;
  
}
/* animation */

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    width: 95%;
    border-radius: 18px;
    background-color: #34393e;
  }
/* End of Animation*/
.popup-overlay{
    backdrop-filter: blur(5px);
}
.modal {
    font-size: 50px;
    width: 100%;
  }
.modal > .header {
width: 100%;
border-bottom: 1px solid gray;
font-size: 18px;
text-align: center;
padding: 5px;
}
.modal > .content {
width: 100%;
padding: 10px 5px;
}
.modal > .close {
cursor: pointer;
position: absolute;
display: block;
padding: 2px 5px;
line-height: 20px;
right: -10px;
top: -10px;
font-size: 24px;
background: #ffffff;
border-radius: 18px;
border: 1px solid #cfcece;
}
