.w3-animate-bottom {
    position: relative;
    animation: animatebottom 0.9s
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@keyframes progres {
    0% {
        width: 0%;
    }

    100% {
        width: 25%;
    }
}

;

@keyframes progres2 {
    0% {
        width: 0%;
    }

    100% {
        width: 100%
    }
}

.fixphone {
    position: relative;
    width: 100%;
    z-index: 2;
    background: url("http://www.w3.org/2000/svg");
    /*  width: '100%';
    display: 'inline-flex';
    margin: '2px'; 
    height: 100%; */
    color: #e62375;
    margin-left: 12px;
    margin-top: 5px;
    font-size: 20px;
}

.fixphone .PhoneInputCountryIconImg {
    width: 100% !important;
}

.alert-danger {
    display: flex;
    background-color: #32364e;
    border-radius: 50%;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    margin: auto;
    justify-content: center;
    padding: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.words {
    display: flex;
    color: #fff;
    font-size: 18px;
}

.emailError {
    color: #fff
}

.country-select {
    position: relative;
    display: inline-block;
    width: 100%;
}

body .formContainer .form input.countryDropdown {
    padding: 4px 12px 4px 50px;
}

.ReactFlagsSelect-module_selectValue__152eS {
    color: #fff !important;
    font-size: 15px;
    margin-top: -5px;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    border-radius: 14px !important;
    color: white;
    background-color: black !important;
    font-weight: 600;
    border: none;
    font-size: 16px;
    outline: none;
    height: 50px;
    margin: auto;
    padding: 4px 12px;
    padding-top: 10px;
    width: 100%;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    background-color: #34393e !important;
    border: none !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU {
    background-color: #34393e !important;
    border: none !important;
}

.labelone {
    color: rgb(170, 169, 169);
    font-size: 10px;
    position: absolute;
    top: -18px;
    left: 12px;
}